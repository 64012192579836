import React, { useState, useEffect } from 'react';
import './index.css'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Home } from './pages/Home';
import { PrivacyPolicy } from './pages/PrivacyPolicy';

function App() {

    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
            </Router>
        </div>
    )
}

export default App