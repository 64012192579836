import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import '../index.css';

export const PrivacyPolicy = () => {
    return (
        <div>
            <div align='center' style={{ margin: '20px' }}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Button type="primary" style={{ marginRight: '10px' }}>Home</Button>
                </Link>
                <Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
                    <Button>Privacy Policy</Button>
                </Link>
            </div>
            <br />
            <div align='center' style={{ margin: '20px' }}>
                <h1>Privacy Policy</h1>
            </div>
        </div>
    )
}